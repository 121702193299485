<template>
  <el-dialog
      title="切换用户"
      :visible="true"
      width="80%"
      :show-close="false"
  >
    <el-form ref="form" label-width="auto">
      <el-form-item label="单位" prop="organizationId">
        <input type="text" name="year"  v-model="year"" />
        <organization-select v-model="form.organizationId"  :is-filter-school="true" filterable @change="changeOrganization"
                             @clear='clear'></organization-select>
      </el-form-item>
      <div v-for="item in items" style="padding:10px">
        
        <el-button @click="reGenReportOrg(item.id)" style="color:brown;padding:5px;margin: 2px 10px;">{{item.name}} </el-button>
        <el-button @click="reGenReport(sch.id)" v-for="sch in schools" v-if="sch.organizationId == item.id" style="color:blueviolet;padding:5px;margin: 2px 10px;">{{sch.name }}</el-button>
      </div>

      <div style="display: flex; align-items: center;">
        <div v-if="users.length">用户</div>
        <div style=" margin-left: 10px">
          <el-button v-for="item in users"
                     :key="item.id"
                     type="primary"
                     plain
                     @click="changeUser(item.id)"
                     style="margin-right: 10px"
                     size="mini"
                     :loading=item.btnLoading
          >{{ item.name +' ' +item.username }}
          </el-button>

        </div>
      </div>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="exitSwitch" :loading=loading>退出切换</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as userApi from "@/api/gen/userApi"
import * as authApi from "@/api/gen/authApi"
import OrganizationSelect from "@/components/OrganizationSelect";
import BaseSelect from "@/components/BaseSelect";
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  name: "batch",
  components: {OrganizationSelect, BaseSelect},
  data() {
    return {
      userApi,
      users: [],
      form: {
        organizationId: '',
        userId: ''
      },
      year:2024,
      loading: false,
      items:[],
      schools:[],
      switchIng: ''


    }
  },
  computed: {
    username() {
      return this.users?.filter(item => item.id === this.form.userId).pop()?.username
    }
  },
  async mounted() {
    this.switchIng = localStorage.getItem('switchIng')
    var res = (await organizationApi.list({pageSize: 10000})).data;
    this.items = res.filter(item=>!(item.type === 3 || item.type === 4))
    this.schools = res.filter(item=>(item.type === 3 || item.type === 4))

  },
  methods: {
    close() {
      this.$emit('close')
    },
    reGenReport(id) {
      organizationApi.reGenReport({schoolId: id,year:this.year,withoutYe:'y'}).then(res => {
        this.$message.success('重新生成报表成功')
      })
    },
    reGenReportOrg(id) {
      organizationApi.reGenReport({orgId: id,year:this.year,withoutYe:'y'}).then(res => {
        this.$message.success('重新生成报表成功')
      })
    },
    //切换单位
    async changeOrganization() {
      if (this.form.organizationId) {
        const res = (await userApi.list({pageSize: 1000, organizationId: this.form.organizationId})).data
        console.log(res)
        res.forEach(item => item.btnLoading = false)
        this.users = res
        this.form.userId = ''
      }


    },
    //切换用户
    async changeUser(id) {
      this.users.filter(item => item.id === id).pop().btnLoading = true
      const res = await authApi.loginByUserId({id: id})
      this.$store.commit("switchUser", res)
      this.$router.push({path:'/'})
      this.$emit('close')
    },
    //退出切换
    async exitSwitch() {
      this.loading = true
      await this.$store.commit("exitSwitch")
      this.$emit('close')

    },
    clear() {
      if (this.users.length) {
        this.users = ""
      }
    }
  },
}
</script>

<style scoped>

</style>
