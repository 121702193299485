import request from '@/utils/request'

import StorageWithExpiry from '@/utils/st'
export function create(data) {

  return request({
    url: '/organization/create',
    method: 'POST',
    data})
}

   

export function createBatch(data) {

  return request({
    url: '/organization/create/batch',
    method: 'POST',
    data})
}

   

export function deleteById(data) {

  return request({
    url: '/organization/deleteById',
    method: 'POST',
    data})
}

   

export function deleteByIds(data) {

  return request({
    url: '/organization/deleteByIds',
    method: 'POST',
    data})
}

   

export function getById(params) {

  return request({
    url: '/organization/getById',
    method: 'GET',
    params})
}

   

export function getByIds(params) {

  return request({
    url: '/organization/getByIds',
    method: 'GET',
    params})
}

   

export async function  list(params) {
  if(!params || JSON.stringify(params) == JSON.stringify({
    "pageSize": 100000
  })){
    params ={pageSize:10000}

    var res = StorageWithExpiry.getItem("__organizationList")
    if(res){
      return res
    }

    var re = await request({
      url: '/organization/list2',
      method: 'POST',
      data:params})

    StorageWithExpiry.setItem('__organizationList', re, 3600 * 1000);
    return re
  }
  var re = await request({
    url: '/organization/list2',
    method: 'POST',
    data:params})
    return re;
  //查看本地是否有__organizationList为key的缓存

  

    //将re 存储到浏览器本地中，key为__organizationList，1个小时过期

// 使用示例：
// 设置一个10秒后过期的数据

}

// export function list(params) {
//   if(!params){
//     params ={pageSize:10000}
//   }
//   return request({
//     url: '/organization/list2',
//     method: 'POST',
//     data:params})
// }
   

export function update(data) {

  return request({
    url: '/organization/update',
    method: 'POST',
    data})
}

   

export function updateBatch(data) {

  return request({
    url: '/organization/update/batch',
    method: 'POST',
    data})
}

 



export function reGenReport(params) {
  return request({
    url: '/h5/testsController/reGenReport',
    method: 'GET',
    params})

}