<template>
  <div>

    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="true"
        :show-close=true
        :title=title
        :visible="true"
        center
        width="90%"
        @close="close"

    >
      <!--骨架-->
      <div v-for="item in 10" v-show="!canShow">
        <div style="display:flex; margin: 8px 0;">
          <template v-for="item in 3">
            <el-skeleton style="width: 100%" animated>
              <template slot="template">
                <div>
                  <div>
                    <el-skeleton-item variant="h1" style="width:15%; margin: 0 1em 0 4em;"/>
                    <el-skeleton-item variant="h1" style="width: 50%;"/>
                  </div>
                </div>
              </template>
            </el-skeleton>
          </template>
        </div>
      </div>

      <el-form
          ref="form"
          v-if="canShow"
          :rules="rules"
          :model="form"
          align="center"
          label-position="right"
          label-width="100px"
          size="medium"
          style="display: flex; flex-wrap: wrap; padding: 0 1px; ">
        <el-form-item label="学校" prop="schoolId">
          <ZxxSchool-select v-model="form.schoolId" ref="schoolId" @change="changeSchool"
                            style="width: 100%;"></ZxxSchool-select>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNo">
          <el-input v-model.trim="form.idNo" maxlength="18"></el-input>
        </el-form-item>
        <div style="display: flex; width: 30%;">
          <el-form-item label="年级" style="width: 100%;">
            <el-select v-model="form.grade" @change="changeGgrade" filterable clearable placeholder="请选择">
              <el-option
                  v-for="(item,index) in grade"
                  :key="index"
                  :label="item.name"
                  :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级" style="width: 100%">
            <el-select v-model="form.classRoomNo" filterable clearable placeholder="请选择">
              <el-option
                  v-for="(item,index) in classNames"
                  :key="index"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>

          </el-form-item>
        </div>
        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="form.name" ref="name"></el-input>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthDate">
          <el-date-picker
              ref="birthDate"
              v-date-format
              v-model="form.birthDate"
              placeholder="选择日期"
              style="width: 100%"
              type="date"
          >
          </el-date-picker>
        </el-form-item>
        <div style="display: flex; width: 30%;">
          <el-form-item label="年龄" prop="age" style="width: 100%;">
            <el-input :class="{red:form.age.length > 3}" v-model.trim="form.age" ref="age"></el-input>

          </el-form-item>
          <el-form-item label="性别" prop="sex" style="width: 100%;">
            <data-select v-model="form.sex" ref="sex" parent-code="Gender"></data-select>
          </el-form-item>
        </div>
        <el-form-item label="学号">
          <el-input v-model.trim="form.studentId" :class="{'red':this.showRedColor_StudentId}"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contact">
          <el-input v-model.trim="form.contact" :class="{'red':showRedColor_Contact}"></el-input>
        </el-form-item>
        <div style="display: flex; width: 30%;">
          <el-form-item label="民族" prop="nationalityCode" style="width: 100%;">
            <nationality-select v-model="form.nationalityCode" ref="nationalityCode" filterable></nationality-select>
          </el-form-item>
          <el-form-item label="城乡" prop="cxType" style="width: 100%;">
            <data-select v-model.trim="form.cxType" ref="cxType" parent-code="CityType"></data-select>
          </el-form-item>
        </div>
        <el-form-item label="检测日期">
          <el-date-picker
              v-model="form.checkDate"
              placeholder="选择日期"
              style="width: 100%;"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="体检年度">
          <year-select v-model="form.year" style="width: 100%;"></year-select>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="营养状况" v-show="status !== 'add'">
          <el-input v-model="yyInfo" disabled></el-input>
        </el-form-item>


        <el-form-item v-for="item in OptionsList" :key="item.id" class="checkItem">
          <div style="display: flex;">

            <template v-if="item.checkItemVO.code !== 'p060' || form.sex == '0' ">
              <check-items

                  class="w-100"

                  :class="[
                               {'red':item.checkItemVO.code === 'p002' && showRedColor_P002},
                               {'red':item.checkItemVO.code === 'p003' && showRedColor_P003},
                               {'red':item.checkItemVO.code === 'p004' && showRedColor_P004},
                               {'red':item.checkItemVO.code === 'p005' && showRedColor_P005},
                               {'red':item.checkItemVO.subCode === 'p005Sub' && showRedColor_P005Sub},
                               {'red':item.checkItemVO.code === 'p009' && showRedColor_P009},
                                {'red':item.checkItemVO.subCode === 'p009Sub' && showRedColor_P009Sub},
                                 {'red':item.checkItemVO.code === 'p025' && showRedColor_P025},
                                 {'red':item.checkItemVO.code === 'p044' && showRedColor_P044},
                                 {'red':item.checkItemVO.code === 'p052' && showRedColor_P052},
                                 {'red':item.checkItemVO.code === 'p053' && showRedColor_P053},
                                 {'red':item.checkItemVO.code === 'p055' && showRedColor_P055},
                                 {'red':item.checkItemVO.code === 'p059' && showRedColor_P059},
                               ]"
                  :config="checkItems[item.checkItemVO.code]" :readOnly="readOnly" :row="rowCurrent"
                  @change="handleChange"/>
            </template>
            <el-input v-model="qqInfo" v-if="item.checkItemVO.code === 'p067'" style="width: 50%;" disabled></el-input>
            <el-input v-model="sgInfo" v-if="item.checkItemVO.code === 'p002'" style="width: 50%;" disabled></el-input>
            <!-- <el-input v-model="tzInfo" v-if="item.checkItemVO.code === 'p003'" style="width: 50%;" disabled></el-input> -->
          </div>

        </el-form-item>


        <el-col v-show="status !== 'check'">
          <div style="display: flex; padding-left:5em; margin: 10px 0">
            <el-button size="mini" type="primary" @click="createResultAdvice">生成结果建议</el-button>
          </div>
        </el-col>
        <el-form-item class="result" label="结果" style="width: 100%;">
          <div style="width: 90%; display:flex;">
            <el-input v-model.trim="form.result" style="width: 100%;" type="textarea" :rows="5"></el-input>
          </div>
        </el-form-item>

        <el-form-item class="suggest" label="建议" style="width: 100%;">
          <div style="width: 90%; display: flex">
            <el-input v-model.trim="form.advice" style="width: 100%;" type="textarea" :rows="5"></el-input>
          </div>
        </el-form-item>

      </el-form>
      <div v-show="canShow" style="display: flex; justify-content: center; margin-top: 20px">
        <span slot="footer" class="dialog-footer" v-if="status !== 'check'">
          <my-button type="primary" @click="update" :auto-loading="true"
                     v-show="$hasPermission('medicalInformation.update')">确 定</my-button>
          <el-button @click="close">取 消</el-button>
          <my-button v-if="status === 'add'" :auto-loading="true" @click="save">保存并继续</my-button>
        </span>

        <span slot="footer" class="dialog-footer" v-else>
             <el-button @click="close">确 定</el-button>
        </span>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import * as medicalInformationApi from "@/api/gen/medicalInformationApi"
import * as checkItemApi from "@/api/gen/checkItemApi"
import * as dataMenu from "@/assets/js/data"
import * as organizationApi from "@/api/gen/organizationApi";
import * as organizationCheckItemStatusApi from "@/api/gen/organizationCheckItemStatusApi"

import ZxxSchoolSelect from "@/components/ZxxSchoolSelect";
import YearSelect from "@/components/yearSelect"
import NationalitySelect from "@/components/nationalitySelect"
import checkItems from "@/components/CheckItems"
import DataSelect from "@/components/DataSelect"
import Autocomplete from '@/components/Autocomplete'
import defaultForm from "@/views/physicalInfomation/defaultForm";
import dateFormat from "@/directive/dateFormat"
import {SGInfo, TZInfo, YYInfo} from "@/plugin/dataInfo";

export default {
  components: {
    checkItems,
    ZxxSchoolSelect,
    YearSelect,
    NationalitySelect,
    DataSelect,
    Autocomplete,
  },
  directive: {
    dateFormat
  },
  name: "edit",
  props: {
    row: {},
    rowCurrent: {}
  },

  provide() {

    return {

      formKey: this.formKey

    }

  },

  data() {
    return {
      debounceMethod:new Function(),
      formKey: new Date().getTime().toString(),
      changeId: new Date().getTime().toString(),
      checkItemList: [],
      form: {
        ...defaultForm, organizationId: this.$store.getters.organizationId
      },

      rules: {
        schoolId: {required: true, message: '请输入学校', trigger: 'blur'},
        name: {required: true, message: '请输入姓名', trigger: 'blur'},
        birthDate: {required: true, message: '请输入出生日期', trigger: 'blur'},
        age: {required: true, message: '请输入年龄', trigger: 'blur'},
        sex: {required: true, message: '请输入性别', trigger: 'blur'},
        nationalityCode: {required: true, message: '请输入名族', trigger: 'blur'},
        cxType: {required: true, message: '请输入城乡', trigger: 'blur'},
      },
      baseDataZWTQQList:[],
      menu: dataMenu,
      gradeInfos: [],
      optionsList: [],

      restaurants: [],

      qqInfo:'',
      sgInfo: SGInfo(this.rowCurrent) ? SGInfo(this.rowCurrent) : '',
      tzInfo: TZInfo(this.rowCurrent) ? TZInfo(this.rowCurrent) : '',
      yyInfo: YYInfo(this.row) ? YYInfo(this.row) : '',


      checkItemStatus: "",
      redColor: 'red',
      showRedColor_StudentId: false,
      showRedColor_Contact: false,
      showRedColor_Age: false,
      showRedColor_P002: false,
      showRedColor_P003: false,
      showRedColor_P004: false,
      showRedColor_P005: false,
      showRedColor_P005Sub: false,
      showRedColor_P009: false,
      showRedColor_P009Sub: false,
      showRedColor_P025: false,
      showRedColor_P044: false,
      showRedColor_P052: false,
      showRedColor_P053: false,
      showRedColor_P055: false,
      showRedColor_P059: false,
    }
  },

  watch: {
    "form.idNo": {
      handler(n) {
        if (n.length === 18) {
          let all = n.slice(6, 14)
          this.form.birthDate = this.$dateString(all)
        }

      }
    },
    'form.birthDate': {
      handler(n) {
        // if (!n) {
        //   this.form.age = ""
        //   return
        // }
        this.form.age = Math.abs(parseInt(this.$diff(n, this.$now(), 'year')?.years));

      }
    },
    'form.age': {
      handler(n) {
        this.showRedColor_Age = n.length > 3
      }
    },
    'form.studentId': {
      handler(n) {
        this.showRedColor_StudentId = n.length > 15
      }
    },
    'form.contact': {
      handler(n) {
        this.showRedColor_Contact = n.length > 11
      }
    },


    'changeId': {
      handler(n) {
        if (this.form.p002) {
          this.showRedColor_P002 = this.form.p002[0] === '2'
        }
        if (this.form.p003) {

          this.showRedColor_P003 = this.form.p003 > 200

        }
        if (this.form.p004) {

          this.showRedColor_P004 = this.form.p004 > 5000

        }
        if (this.form.p005) {

          this.showRedColor_P005 = this.form.p005.length > 5

        }
        if (this.form.p005Sub) {
          this.showRedColor_P005Sub = this.form.p005Sub.length > 5

        }
        if (this.form.p009) {

          this.showRedColor_P009 = this.form.p009 > 5.0

        }
        if (this.form.p009Sub) {

          this.showRedColor_P009Sub = this.form.p009Sub > 5.0

        }
        if (this.form.p025) {

          this.showRedColor_P025 = this.form.p025.length > 5

        }
        if (this.form.p044) {

          this.showRedColor_P044 = this.form.p044 > 200

        }
        if (this.form.p052) {

          this.showRedColor_P052 = this.form.p052.length > 4

        }
        if (this.form.p053) {

          this.showRedColor_P053 = this.form.p053.length > 4

        }
        if (this.form.p055) {

          this.showRedColor_P055 = this.form.p055.length > 4

        }
        if (this.form.p059) {

          this.showRedColor_P059 = this.form.p059.length > 4

        }

      }
    },
  },

  computed: {
    status() {
      return this.row.status
    },
    title() {
      if (this.status === 'add') return '体检信息—新增'
      if (this.status === 'edit') return '体检信息—编辑'
      if (this.status === 'check') return '体检信息—查看'
    },
    checkItems() {
      return this._.chain(this.checkItemList).groupBy(item => item.code).mapValues(x => x[0]).value();
    }
    ,
    canShow() {

      return Object.keys(this.checkItems)?.length > 0;
    }
    ,

    CityEnum() {
      return Object.values(this.menu.CityEnum)
    }
    ,
    OrganizationTypeEnum() {
      return Object.values(this.menu.OrganizationTypeEnum)
    }
    ,
    GenderEnum() {
      return Object.values(this.menu.GenderEnum)
    }
    ,
    OptionsList() {
      if (this.status === 'add') {
        return this.optionsList.filter(item => item.status === 1);

      }
      if (this.status === 'edit') {
        return this.optionsList.filter(item => item.status === 1)
      }
      if (this.status === 'check') {
        return this.optionsList.filter(item => item.status === 1)
      }
    }
    ,
    grade() {

      return this.gradeInfos

    }
    ,

    classNames() {
      return this.grade?.filter(item => item.name === this.form.grade)[0]?.classNames
    }
    ,


    readOnly() {
      if (this.status === 'check') {
        return true
      }
    },


  },
  async mounted() {

      var copy = this.form;
      var data = this.row;      
      for (let key in data) {
        if(data[key]){
          copy[key] = data[key]
        }
      }
    // Object.assign(this.form, this.row)
    var res =  await medicalInformationApi.baseDataZWTQQList({});
    this.baseDataZWTQQList = res.data;
    await this.checkItem()
    await this.tableList()
    await this.getUserInfo()
    if (!this.form.checkDate) {
      this.form.checkDate = this.$dateTimeString(this.$now())
    }

    await this.changeSchool()

    var _that =this;
    this.debounceMethod = this.debounce(function(val){
      var sex = _that.form.sex =="0"?"男":"女"
      var list = _that.baseDataZWTQQList.filter(one=> _that.form.grade == one.grade && sex ==one.sex ) 
      list = _that._.sortBy(list, ['val']);
      var _val = parseFloat(val)
      console.log(_val);   
      if(val!='' &&_that._.isNumber(_val)&&list.length>0){
        var qqInfo=""
        for(var i =0 ;i<list.length;i++){
          var current = list[i].val;
          var last = -1000;
          if(i>0){
           last = list[i-1].val;
          }
          if(Math.abs(current-_val)<0.000001){
            qqInfo = list[i].level +"("+list[i].score+")";
          }else if(current>_val){
            if(_val>=last){
              if(i==0){
                qqInfo="不及格"+"("+list[i].score+")";
              }else{
                qqInfo = list[i-1].level +"("+list[i-1].score+")";;
              }
            }
          }
        }

        if(qqInfo==''){
          qqInfo ="优秀"+"("+list[list.length-1].score+")";
        }
        _that.qqInfo = qqInfo;
      }else{
        _that.qqInfo = "";
      }

    }, 1000)

  },

  methods: {
    
    //获取个人体检信息
    async getUserInfo() {
      // if (this.row.id) {

        const res = await medicalInformationApi.getById({id: this.row.id});
      
      var copy = this.form;
      var data = res;      
      for (let key in data) {
        if(data[key]&&key!="checkItemStatus"){
          copy[key] = data[key]
        }
      }
      if(res){
        this.qqInfo = res.p067Result
      }

        // this.form = res


      // }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    // {"p001": '正常', 'p001Sub': ""}
    handleChange(data) {
      // // 写法1
      // for (const k of Object.keys(data)) {
      //   (async () => this.$set(this.form, k, data[k]))();
      //
      // }
      //
      // // 写法2
      // this.form = {...this.form, ...data};
      Object.keys(data).forEach((v) => this.form[v] = data[v]);
      console.log(data)
      this.changeId = this.uuidv4();
      var that =this
      var p67 = Object.keys(data).filter(one=>one=="p067")
      if(p67.length>0){
        that.p067 = data.p067;
        this.debounceMethod(data.p067)
      }

      // this.form = Object.assign({},data,this.form)
    },
    debounce(fun, delay) {
        return function (args) {
            //获取函数的作用域和变量
            let that = this
            let _args = args
            //每次事件被触发，都会清除当前的timeer，然后重写设置超时调用
            clearTimeout(fun.id)
            fun.id = setTimeout(function () {
                fun.call(that, _args)
            }, delay)
        }
    }
    ,
    //取消
    close() {
      this.$emit("close")
    }
    ,
    //确定
    async update(done) {
      try {
        let status;
        if (this.status === 'add') {
          status = await this.add();
        } else {
          status = await this.edit()
        }
        if (status) {
          this.$emit('close')
          this.$emit('update')
        }
      } finally {
        done()

      }

    }
    ,

    async add() {

      return new Promise(resolve => {


        this.$refs.form.validate(async (valid, field) => {
          if (valid) {

            if (this.form.sex === '1') {
              this.form.p060 = '未做'
            }


            let clone = {...this.form}
            clone.birthDate = this.$dateTimeString(this.form.birthDate)
            console.log(clone)

            if (this._.isEmpty(this.form.result) && this._.isEmpty(this.form.advice)) {
              await this.createResultAdvice();
              clone.result = this.form.result;
              clone.advice = this.form.advice;
            }
            clone.p067Result = this.qqInfo;
            await medicalInformationApi.create({...clone})
            this.$message.success('添加成功')
          } else {
            this.$message.error('请输入必填项');
            let keys = Object.keys(field);
            for (const key of keys) {
              try {
                this.$refs[key].focus();
                break;
              } catch (e) {
                console.log(e);
              }
            }
          }
          resolve(valid);
        })

      })


    },
    async edit() {
      return new Promise(resolve => {
        this.$refs.form.validate(async (valid, field) => {
          if (valid) {
            if (this.form.sex === '1') {
              this.form.p060 = '未做'
            }

            let clone = {...this.form}
            clone.birthDate = this.$dateTimeString(clone.birthDate)

            if (this._.isEmpty(this.form.result) && this._.isEmpty(this.form.advice)) {
              await this.createResultAdvice();
              clone.result = this.form.result;
              clone.advice = this.form.advice;
            }            
            clone.p067Result = this.qqInfo;
 

            await medicalInformationApi.update({...clone})
            this.$message.success('修改成功')
          } else {
            this.$message.error('请输入必填项');
            let keys = Object.keys(field);
            for (const key of keys) {
              try {
                this.$refs[key].focus();
                break;
              } catch (e) {
                console.log(e);
              }
            }
          }
          resolve(valid)
        })
      })
    },

    //保存并继续
    async save(done) {
      await this.add();
      this.form.name = ""
      this.form.idNo = ""
      this.form.studentId = ""
      this.form.birthDate = ""
      this.form.contact = ""
      this.form.result = ""
      this.form.advice = ""
      this.form.age = ""
      this.qqInfo = ""
      this.$store.commit("updateFromKey")


      this.$emit("update")
      done()
      this.$nextTick(() => {
        this.$refs.name.focus()
      })
    }
    ,

    async checkItem() {
      const res = (await checkItemApi.list({pageSize: 10000000}));
      // res.data.forEach((item => item.status = 0));
      this.checkItemListTmp = res.data.filter(item => item?.types?.includes(1))


    }
    ,


    async tableList() {
      console.log(this.form.organizationId)
      const res = (await organizationCheckItemStatusApi.list({
        organizationId: this.form.organizationId,
        pageSize: 1000002
      }));

      this.optionsList = this._.chain(res.data).filter(item => item.checkItemVO?.types?.includes(1)).sortBy(x => parseFloat(x.sortKey)).value()

      let status = "";

      for (let i = 0; i < 100; i++) {

        let code = ("p" + (i + "").padStart(3, "0"));
        // console.log(code);

        if (this.optionsList.filter(x => x.checkItemVO.code === code)?.pop()?.status === 1) {

          status += "1";
        } else {
          status += "0";
        }

      }

      this.form.checkItemStatus = status


      for (let item of this.checkItemListTmp) {
        for (let k of this.optionsList) {
          if (item.id === k.checkItemId) {
            item.status = k.status
            // this.$set(item, 'status', k.status);

          }
        }
      }
      this.checkItemList = [...this.checkItemListTmp];


    }
    ,

    async createResultAdvice() {
      let clone = {...this.form}
      clone.birthDate = this.$dateTimeString(clone.birthDate)
      const resp = await medicalInformationApi.getResultAndAdvice({...clone})
      var reslut = resp.map((item, index) => `${index + 1} ` + item.result).join('\n')
      var advice = resp.flatMap(item => item.advice).join(`\n`)
     
      if(!advice &&reslut){
        reslut = resp.map((item) =>  item.result).join('\n')
      }
      this.form.result =reslut
      this.form.advice = advice
    },

//更换年级
    changeGgrade() {
      this.form.classRoomNo = ''
    }
    ,

    //更换学校
    async changeSchool() {
      if (this.form.schoolId) {
        const res = await organizationApi.getById({id: this.form.schoolId})
        this.gradeInfos = res.gradeInfos
        this.form.cxType = res.cxType
      } else {
        this.form.grade = ""
        this.form.classRoomNo = ''
      }

    }

  }
}
</script>

<style scoped>

.w-40p {
  width: 40% !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.el-input {
  width: 100%;
}


.el-form-item {
  font-size: 12px;
  width: 30%;
  margin-bottom: 0;

}

.el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input--medium .el-input__inner {
  height: 28px !important;
}


.el-select {
  width: 100%;
}

/deep/ .result .el-input__inner {
  height: 73px !important;
}

/deep/ .suggest .el-input__inner {
  height: 47px !important;
}

/deep/ .result .el-form-item__content,
/deep/ .suggest .el-form-item__content {
  margin-left: 0 !important;
}

/deep/ .checkItem .el-form-item__content,
/deep/ .checkItem .el-form-item__content {
  margin-left: 0 !important;
}


/deep/ .el-form-item__error {
  display: none;

}


.check-items {
  justify-content: flex-end;
}

.check-items + .check-items {

}

/deep/ .el-dialog__header {
  padding: 5px 20px 5px;
}

/deep/ .el-dialog--center .el-dialog__body {
  padding: 10px 25px 30px;
}

/deep/ .el-dialog__title {
  font-size: 16px;
}

/*/deep/.el-form-item__content{*/
/*  margin-right: 80px !important;*/
/*}*/
.red >>> input {
  color: red !important;
}

</style>
