<template>
  <el-select  v-bind="$attrs" v-on="$listeners" clearable style="width: 100%">
    <el-option v-for="item in items" :key="item.id" :label="item.name" :value="item.code"></el-option>
  </el-select>


</template>

<script>
import SetDefaultValue from "@/mixins/SetDefaultValue";

export default {
  inheritAttrs: false,

  name: "nationalitySelect",
  mixins: [SetDefaultValue],
  async beforeMount() {
    // let hanzhu = '609b47f5ee0ecb628f5dcdc1'
    var sublist=['汉族','傣族','彝族','拉祜族','回族','哈尼族','布朗族']
    var list = this.$store.state.nationalityList.reverse();
    list.sort(function (a,b) {
      var idx1= sublist.indexOf(a.name)
      var idx2=  sublist.indexOf(b.name)
      if(idx1>-1 && idx2>-1){
        return idx1-idx2
      }else{
        if(idx1==-1 ){
          return 1
        }
        if(idx2==-1){
          return -1
        }
      }
    })
    this.items = list;

    // this.setDefaultValue(hanzhu)

  },

  data() {
    return {
      items: [],
    }
  },
};
</script>

<style scoped>

</style>
