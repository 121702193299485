  

const StorageWithExpiry = {
  // 设置带过期时间的数据
  setItem(key, value, ttl) {
      const item = {
          value: value,
          expiry: ttl ? new Date().getTime() + ttl : null
      };
      localStorage.setItem(key, JSON.stringify(item));
  },

  // 获取数据，如果过期返回null
  getItem(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) return null;

      const item = JSON.parse(itemStr);
      
      // 检查是否设置了过期时间
      if (!item.expiry) return item.value;

      // 检查是否已过期
      if (new Date().getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
      }

      return item.value;
  },

  // 删除数据
  removeItem(key) {
      localStorage.removeItem(key);
  },

  // 清空所有数据
  clear() {
      localStorage.clear();
  }
};

export default StorageWithExpiry;