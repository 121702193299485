<template>
  <el-select :value="myValue" v-bind="myAttrs" v-on="myListeners" ref="select" clearable filterable>
    <el-option v-for="item in items" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>


</template>

<script>
import * as organizationApi from "@/api/gen/organizationApi"

export default {
  inheritAttrs: false,
  name: "ZxxSchoolSelect",
  async beforeMount() {

    await this.list()

  },

  data() {
    return {
      status: 'init',
      items: [],
      orgList: []
    }
  },
  props: {
    value: {}
  },
  methods: {
    async list() {
      this.orgList = (await organizationApi.list()).data
      if (this.$store.getters.isSuperAdmin || this.$hasPermission('allZxxSchool') || this.$store.getters.username === 'admin') {
        console.log("allZxxSchool");


        this.items = (await organizationApi.list({pageSize:100000})).data.filter(item => item.type === 3)
      } else {
        console.log("allZxxSchool-temp");
        let tmp = (await organizationApi.getById({id: this.$store.getters.organizationId})).schools
        if (!tmp) {
          return
        }
        if (tmp.length) {
          let res = (await organizationApi.getById({id: this.$store.getters.organizationId}))
          this.items = (await organizationApi.list({id: this._.chain(res.schools).join(",").value(),pageSize:100000})).data.filter(item => item.type === 3)
        }
      }

      this.done()
    },

    getItems() {
      return this.items
    },

    focus() {
      this.$refs.select.focus()
    },

    done() {
      this.status = 'done'
      this.$emit('initDone',this.items)
    },
    gerOrgList() {

    }
  },

  computed: {
    myValue() {
      if (this.items.length <= 0) {
        return ""
      }
      return this.value
    },
    myAttrs() {
      return this.$attrs
    },
    myListeners() {
      let old = this.$listeners.input
      this.$listeners.input = (v) => {
        if (this.status === 'init') {
          old(this.value)
        } else {
          old(v)
        }
      }
      return this.$listeners
    }
  },
  watch: {
    myValue: {
      handler(n) {
        let orgName = this.orgList.filter(item => item.schools?.includes(n))?.pop()?.name
        let schoolName = this.orgList.filter(item => item.id === n)?.pop()?.name
        this.$store.commit('setTableName', orgName)
        this.$store.commit('setTableSchoolName', schoolName)
      }
    }
  },
}
</script>

<style scoped>

</style>
