import { render, staticRenderFns } from "./nationalitySelect.vue?vue&type=template&id=163d31e8&scoped=true&"
import script from "./nationalitySelect.vue?vue&type=script&lang=js&"
export * from "./nationalitySelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163d31e8",
  null
  
)

export default component.exports